import api from "@modules/api/api";
import { getGuestUser, User } from "@modules/user/types/user";
import userQueryKeys from "@modules/user/userQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UserFavouriteVehicle } from "../types/UserFavouriteVehicle";

const addUserFavouriteVehicle = async (
  id: string
): Promise<UserFavouriteVehicle> => {
  return api.post<UserFavouriteVehicle>("api/customers/me/favourites", {
    json: {
      vehicleId: id,
    },
  });
};

export default function useAddUserFavouriteVehicleMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addUserFavouriteVehicle,
    onSuccess: (newFavourite) => {
      queryClient.setQueryData(
        userQueryKeys.user(),
        (data: User | undefined) => {
          const user = data || getGuestUser();
          const { favouriteVehicles } = user;

          return {
            ...user,
            favouriteVehicles: [...favouriteVehicles, newFavourite],
          };
        }
      );
    },
  });
}
