import Button, { ButtonSpacing } from "@common/components/Button";
import Link from "next/link";
import { Dispatch, ReactNode, SetStateAction } from "react";
import { useRouter } from "next/router";
import ActionButton from "../enum/ActionButtonEnum";
import GetButtonStyle from "../helpers/getButtonStyle";
import getRelativeLink from "../helpers/getRelativeLink";
import { CmsButtonType } from "../types/CmsButton";
import { ButtonModalState } from "./ButtonModalContainer";

type Props = {
  button: CmsButtonType;
  setButtonModalState: Dispatch<SetStateAction<ButtonModalState>>;
  className?: string;
  onClick?: () => void;
  rightIcon?: ReactNode;
  htmlIdRef?: React.MutableRefObject<HTMLDivElement | null>;
  spacing?: ButtonSpacing;
};

const CmsButton = ({
  button,
  onClick,
  setButtonModalState,
  rightIcon,
  className,
  htmlIdRef,
  spacing,
}: Props) => {
  const { asPath } = useRouter();

  const getButton = () => {
    const btn = (
      <Button
        className={className}
        fill={button.buttonType || "filled"}
        buttonStyle={GetButtonStyle(button) || "primary"}
        spacing={spacing}
        onClick={() => {
          if (!button.isButtonLink) {
            setButtonModalState({
              show: true,
              actionType: button.actionButton as unknown as ActionButton,
              gubagooEventId:
                button.actionButton === ActionButton.OpenGubagooChat
                  ? button.gubagooEventId
                  : undefined,
              formProps:
                button.actionButton === ActionButton.GravityForm
                  ? button.formData?.formSide
                  : undefined,
            });
          }

          if (onClick) {
            onClick();
          }
          if (window.location.hash) {
            htmlIdRef?.current?.scrollIntoView({
              behavior: "smooth",
            });
          }
        }}
        rightIcon={rightIcon}
        aria-label={`${
          button.isButtonLink
            ? button?.buttonLink?.title ?? ""
            : button?.actionButtonTitle
        }`}
      >
        {button.isButtonLink
          ? button?.buttonLink?.title ?? ""
          : button?.actionButtonTitle}
      </Button>
    );

    if (button?.isButtonLink && button?.buttonLink?.url) {
      let linkUrl = button?.buttonLink?.url;

      if (button?.persistQueryParams) {
        const queryParams = asPath.split("?")[1];
        linkUrl = queryParams
          ? `${button.buttonLink?.url}?${queryParams}`
          : button.buttonLink?.url;
      }

      return (
        <Link
          href={getRelativeLink(linkUrl)}
          target={button.buttonLink?.target || ""}
        >
          {btn}
        </Link>
      );
    }
    return btn;
  };

  return getButton();
};

export default CmsButton;
