import { gtmPushData } from "@common/helpers/gtm";
import Location from "@modules/locations/types/Location";
import "@js-joda/timezone/dist/js-joda-timezone-10-year-range";
import React, { useState } from "react";
import { useTranslation } from "next-i18next";
import { convert, LocalDate } from "@js-joda/core";
import { useRouter } from "next/router";
import Button from "@components/Button";
import { ChevronUpIcon, ChevronDownIcon } from "@common/components/icons";
import LocationHours from "@modules/locations/types/LocationHours";
import SpecialHoursPeriod from "@modules/locations/types/SpecialHoursPeriod";
import { capitalizeFirstLetter } from "@common/helpers/stringHelper";
import {
  getHoursFormatted,
  getDateFormatted,
  dateTimeFormatter,
} from "@common/helpers/dateTimeHelper";

type Props = {
  location: Location;
  detailed: boolean;
  eventTag: string;
};

type DayRangeWithHours = {
  startDay: string;
  endDay?: string;
  timeRange: string;
  canAddToRange: boolean;
};

const CurrentHours = ({
  location,
  detailed = false,
  eventTag = "Locations",
}: Props) => {
  const router = useRouter();
  const { t } = useTranslation(["common"]);
  const [showDetails, setShowDetails] = useState<boolean>(false);
  const today = LocalDate.now();
  const currentWeekdayName = today.dayOfWeek().name().toLowerCase();
  const todaysHours = location.hours.find(
    (lh) => lh.openDay.toLowerCase() === currentWeekdayName.toLowerCase()
  );

  const weekdays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const locationsWithMissingDays = location.hours;

  weekdays.forEach((x) => {
    if (
      !locationsWithMissingDays.find(
        (y) => y.openDay.toLowerCase() === x.toLowerCase()
      )
    ) {
      locationsWithMissingDays.push({
        openDay: x,
        openTime: undefined,
        closeTime: undefined,
        closeDay: x,
      });
    }
  });

  const sortedDaysOfTheWeek = locationsWithMissingDays.sort(function sortByDay(
    a: LocationHours,
    b: LocationHours
  ) {
    const day1 = a.openDay;
    const day2 = b.openDay;

    return (
      weekdays.indexOf(capitalizeFirstLetter(day1)) -
      weekdays.indexOf(capitalizeFirstLetter(day2))
    );
  });

  let showOpeningDate = false;

  if (location.openingDate) {
    const openingDate = LocalDate.parse(location.openingDate);

    if (openingDate.isAfter(today)) {
      showOpeningDate = true;
    }
  }

  const toggleDetailsState = () => {
    gtmPushData({
      event: eventTag,
      element: "Dropdown",
      descriptor: "View-Hours",
    });
    setShowDetails((prevState) => !prevState);
  };

  const createHoursText = (openTime: string, closeTime: string) => {
    return `${getHoursFormatted(
      openTime,
      router.locale ?? "en"
    )} - ${getHoursFormatted(closeTime, router.locale ?? "en")}`;
  };

  const groupDaysBySameHours = (locationHours: LocationHours[]) => {
    const result: DayRangeWithHours[] = [];

    locationHours.forEach((x) => {
      if (x.openTime && x.closeTime) {
        const currentHourRange = createHoursText(x.openTime, x.closeTime);
        const existingRangeIndex = result.findIndex(
          (dr) => dr.timeRange === currentHourRange
        );

        if (
          existingRangeIndex === -1 ||
          !result[existingRangeIndex].canAddToRange
        ) {
          // mark old array entries as not modifiable
          result.map((e) => {
            e.canAddToRange = false;
            return e;
          });

          const newRange: DayRangeWithHours = {
            startDay: x.openDay,
            endDay: undefined,
            timeRange: currentHourRange,
            canAddToRange: true,
          };
          result.push(newRange);
        } else if (result[existingRangeIndex].canAddToRange) {
          result[existingRangeIndex].endDay = x.openDay;
        }
      } else {
        const newRange: DayRangeWithHours = {
          startDay: x.openDay,
          endDay: undefined,
          timeRange: t("common:closed"),
          canAddToRange: true,
        };

        result.push(newRange);
      }
    });

    return result;
  };

  const hoursGroupedByDays = groupDaysBySameHours(sortedDaysOfTheWeek);

  const getFormattedSpecialHoursDate = (
    specialHours: SpecialHoursPeriod
  ): string => {
    return getDateFormatted(specialHours.start, router.locale ?? "en");
  };

  const getFormattedOpeningDate = (): string => {
    if (location.openingDate) {
      const localDate = LocalDate.parse(location.openingDate);

      return dateTimeFormatter(
        convert(localDate).toDate(),
        router.locale ?? "en",
        "MMM D, YYYY"
      );
    }
    return "";
  };

  const getFormattedSpecialHoursHours = (
    specialHours: SpecialHoursPeriod
  ): string => {
    if (specialHours.isClosed) {
      return t("common:closed");
    }
    const openDate = new Date(specialHours.start);
    const closeDate = new Date(specialHours.end);

    return `${getHoursFormatted(
      `${openDate.getHours()}:${openDate.getMinutes()}`,
      router.locale ?? "en"
    )} - ${getHoursFormatted(
      `${closeDate.getHours()}:${closeDate.getMinutes()}`,
      router.locale ?? "en"
    )}`;
  };

  const getOpenStatus = (locationInfo: Location) => {
    if (showOpeningDate) {
      return (
        <div>
          <span className="font-semibold text-primary-bright">
            {t("common:opening_on", { date: getFormattedOpeningDate() })}
          </span>
        </div>
      );
    }
    if (locationInfo.isOpen) {
      return (
        <div>
          <span className="font-semibold text-primary-bold">
            {t("common:open_now")}
          </span>
        </div>
      );
    }
    if (locationInfo.isTemporarilyClosed) {
      return (
        <div>
          <span className="font-semibold text-secondary-maroon-300">
            {t("common:temporarily_closed")}
          </span>
        </div>
      );
    }
    return (
      <div>
        <span className="font-semibold text-secondary-maroon-300">
          {t("common:closed_now")}
        </span>
      </div>
    );
  };
  return (
    <div>
      <div className="flex items-start md:justify-start xs:justify-between xs:gap-0 sm:gap-4 xs:grid-cols-2 md:grid-cols-2">
        <div className="flex xs:flex-wrap xs:w-1/2 md:2/3 lg:w-fit">
          <div>{getOpenStatus(location)}</div>
        </div>
        {detailed && !location.isTemporarilyClosed && (
          <div className="mb-1 flex">
            <Button
              buttonStyle="primary"
              fill="outline"
              size="small"
              onClick={toggleDetailsState}
              rightIcon={
                showDetails ? (
                  <ChevronUpIcon className="h-5 w-5" />
                ) : (
                  <ChevronDownIcon className="h-5 w-5" />
                )
              }
              spacing="no-padding"
            >
              {t("common:view_hours")}
            </Button>
          </div>
        )}
      </div>
      <div className="flex w-fit">
        {!showOpeningDate &&
          !location.isTemporarilyClosed &&
          todaysHours &&
          todaysHours.openTime &&
          todaysHours.closeTime && (
            <div>
              {todaysHours && todaysHours.openTime && todaysHours.closeTime && (
                <div>
                  <span>
                    {`${capitalizeFirstLetter(
                      todaysHours.openDay
                    )} ${createHoursText(
                      todaysHours.openTime,
                      todaysHours.closeTime
                    )}`}
                  </span>
                </div>
              )}
            </div>
          )}
      </div>
      {showDetails && (
        <div className="my-5 flex flex-col grid grid-cols-2 bg-gray-25 p-4 rounded-lg text-left">
          <div className="col-span-2">
            <p className="font-semibold">{t("common:regular_hours")}</p>
          </div>
          {hoursGroupedByDays.map((groupedDays) => (
            <React.Fragment key={groupedDays.startDay}>
              <div>
                <p>
                  {capitalizeFirstLetter(groupedDays.startDay)}
                  {groupedDays.endDay
                    ? ` - ${capitalizeFirstLetter(groupedDays.endDay)}`
                    : ""}
                </p>
              </div>
              <div>
                <p>{groupedDays.timeRange}</p>
              </div>
            </React.Fragment>
          ))}
          {location.specialHoursPeriods.length > 0 && (
            <div className="col-span-2 mt-4">
              <p className="font-semibold">{t("common:special_hours")}</p>
            </div>
          )}
          {location.specialHoursPeriods.map((specialHours) => (
            <React.Fragment key={specialHours.start}>
              <div>
                <p>{getFormattedSpecialHoursDate(specialHours)}</p>
              </div>
              <div>
                <p>{getFormattedSpecialHoursHours(specialHours)}</p>
              </div>
            </React.Fragment>
          ))}
        </div>
      )}
    </div>
  );
};

export default CurrentHours;
