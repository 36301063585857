import useEmblaCarousel from "embla-carousel-react";
import { useCallback, useEffect, useState } from "react";
import VehicleCarouselButtons from "./VehicleCarouselButtons";
import VehiclePhotoSlide from "./VehiclePhotoSlide";
import { NewArrivalImageVLP } from "./NewArrivalImage";
import VehicleList from "../types/vehicleList";

type Props = {
  vehicle: VehicleList;
  altText: string;
  isActive: boolean;
  isHovering: boolean;
  isAboveTheFold: boolean;
  onImageSlide: () => void;
};
const VehiclePhotoCarousel = ({
  vehicle,
  altText,
  isActive,
  isHovering,
  isAboveTheFold,
  onImageSlide,
}: Props) => {
  const [viewportRef, embla] = useEmblaCarousel({
    align: "start",
    loop: false,
    skipSnaps: false,
    active: isActive,
  });

  const goToPrevious = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (embla) {
        if (embla.canScrollPrev()) {
          embla.scrollPrev(true);
        } else {
          embla.scrollTo(vehicle.images.length - 1, true);
        }
      }

      if (e) {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }
    },
    [embla, vehicle.images]
  );

  const goToNext = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (embla) {
        if (embla.canScrollNext()) {
          embla.scrollNext(true);
        } else {
          embla.scrollTo(0, true);
        }
      }
      if (e) {
        e.preventDefault();
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
      }
    },
    [embla]
  );

  useEffect(() => {
    if (embla) {
      embla.on("select", onImageSlide);
    }
    return () => {
      if (embla) {
        embla.off("select", onImageSlide);
      }
    };
  }, [embla, onImageSlide]);

  const [error, setError] = useState<boolean>(false);

  return !error && vehicle.images.length > 1 ? (
    <div className="embla-viewport" ref={viewportRef}>
      <div className="embla-container">
        {vehicle.images.map((image, index) => (
          <VehiclePhotoSlide
            image={image}
            altText={altText}
            key={image.position}
            imageLoading={index === 0 && isAboveTheFold ? "eager" : "lazy"}
            priority={index === 0 && isAboveTheFold}
            onError={() => setError(true)}
          />
        ))}
      </div>
      {vehicle.images.length > 1 && (
        <div className="hidden lg:block">
          <VehicleCarouselButtons
            goToPrevious={goToPrevious}
            goToNext={goToNext}
            isHovering={isHovering}
          />
        </div>
      )}
    </div>
  ) : (
    <NewArrivalImageVLP
      vehicle={vehicle}
      width={640}
      height={480}
      className="inline w-full object-cover h-auto rounded-t-lg pb-2"
    />
  );
};

export default VehiclePhotoCarousel;
