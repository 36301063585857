import SeoInfoProps from "@common/types/SeoInfoProps";
import Head from "next/head";
import { removeTrailingBackslash } from "@common/helpers/stringHelper";

type Props = {
  seoInfo: SeoInfoProps;
};

const SeoInfo = ({
  seoInfo: {
    title,
    metaDesc,
    metaKeywords,
    canonical,
    opengraphType,
    opengraphTitle,
    opengraphDescription,
    opengraphUrl,
    opengraphSiteName,
    opengraphPublishedTime,
    opengraphModifiedTime,
    opengraphImage,
    twitterTitle,
    twitterDescription,
    twitterImage,
  },
}: Props) => {
  return (
    <Head>
      {title && <title>{title}</title>}
      {metaDesc && <meta name="description" content={metaDesc} />}
      {metaKeywords && <meta name="keywords" content={metaKeywords} />}
      {canonical && <link rel="canonical" href={canonical} />}
      {opengraphType && <meta property="og:type" content={opengraphType} />}
      {opengraphTitle && <meta property="og:title" content={opengraphTitle} />}
      {opengraphDescription && (
        <meta property="og:description" content={opengraphDescription} />
      )}
      {opengraphUrl && (
        <meta
          property="og:url"
          content={removeTrailingBackslash(opengraphUrl)}
        />
      )}
      {opengraphSiteName && (
        <meta property="og:site_name" content={opengraphSiteName} />
      )}
      {opengraphPublishedTime && (
        <meta
          property="article:published_time"
          content={opengraphPublishedTime}
        />
      )}
      {opengraphModifiedTime && (
        <meta
          property="article:modified_time"
          content={opengraphModifiedTime}
        />
      )}
      {opengraphImage?.sourceUrl && (
        <meta property="og:image" content={opengraphImage?.sourceUrl} />
      )}
      {opengraphImage?.mediaDetails?.width && (
        <meta
          property="og:image:width"
          content={opengraphImage?.mediaDetails?.width}
        />
      )}
      {opengraphImage?.mediaDetails?.height && (
        <meta
          property="og:image:height"
          content={opengraphImage?.mediaDetails?.height}
        />
      )}

      {twitterTitle && <meta name="twitter:title" content={twitterTitle} />}
      {twitterDescription && (
        <meta name="twitter:description" content={twitterDescription} />
      )}
      {twitterImage?.sourceUrl && (
        <meta name="twitter:image" content={twitterImage?.sourceUrl} />
      )}
    </Head>
  );
};

export default SeoInfo;
