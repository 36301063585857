import classNames from "classnames";
import CloudflareImagesImage from "@common/components/CloudflareImagesImage";
import { VehicleCardPromoBanner } from "../types/VehicleCardPromoBanner";

type Props = {
  className?: string;
} & VehicleCardPromoBanner;

const VlpVehicleCardPromoBanner = ({
  className,
  option,
  backgroundColor,
  content,
  textSize,
  image,
  imageOptions,
}: Props) => {
  const isColorAndContent = option === "Background Color + Content";
  const backgroundColour =
    isColorAndContent && backgroundColor ? backgroundColor : "transparent";
  const textSizeClass = textSize ? `text-${textSize}` : "";
  const defaultPosition = "top-1 sm:top-3 left-3";
  const colorAndContentClassNames = `${defaultPosition} text-center p-2 shadow-elevation-02`;

  const position = imageOptions?.position ?? defaultPosition;
  const imageClassNames = `${position} overflow-visible no-scrollbar bg-cover bg-center`;
  const hasBackgroundImage = option === "Image" && image && image.length > 0;

  const optionClassNames = isColorAndContent
    ? colorAndContentClassNames
    : imageClassNames;

  const roundedClassName = imageOptions?.rounded ? "rounded-lg" : "";

  return (
    <div
      className={classNames(
        `rounded-lg border-transparent`,
        className,
        optionClassNames
      )}
      style={{
        backgroundColor: backgroundColour,
      }}
    >
      {isColorAndContent && content && (
        <div
          className={classNames(textSizeClass)}
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      )}
      {!isColorAndContent && hasBackgroundImage && (
        <CloudflareImagesImage
          className={roundedClassName}
          src={image[0].sourceUrl}
          alt={image[0].altText}
          width={imageOptions?.width || 100}
          height={100}
        />
      )}
    </div>
  );
};

export default VlpVehicleCardPromoBanner;
