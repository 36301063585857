enum ActionButton {
  OpenCreditApp = "OpenCreditApp",
  OpenTradeAppraisal = "OpenTradeAppraisal",
  OpenCreditCheck = "OpenCreditCheck",
  OpenGubagooChat = "OpenGubagooChat",
  GravityForm = "GravityForm",
  UnAssigned = "UnAssigned",
}

export default ActionButton;
