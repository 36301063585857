import { useTranslation } from "next-i18next";
import Modal from "@common/components/Modal";
import Location from "@modules/locations/types/Location";
import { EmailIcon, MapPinIcon, PhoneIcon } from "@common/components/icons";
import { formatPhoneNumber } from "@common/helpers/stringHelper";
import CurrentHours from "@modules/locations/components/individual/CurrentHours";
import GoogleMapsWrapper from "@modules/location/helpers/GoogleMapsWrapper";

type Props = {
  handleModalClose(): void;
  showModal: boolean;
  location: Location;
};

const getFullAddress = (location: Location) => {
  return `${
    location.address?.address === null ? "" : `${location.address.address},`
  } ${location.address?.city === null ? "" : `${location.address.city},`} ${
    location.address?.province === null ? "" : `${location.address.province},`
  } ${
    location.address?.postalCode === null
      ? ""
      : `${location.address.postalCode}`
  }`;
};

const VehicleLocationModal = ({
  handleModalClose,
  showModal,
  location,
}: Props) => {
  const { t } = useTranslation(["common", "vehicle"]);

  const fullAddress = getFullAddress(location);

  if (!location) return null;

  return (
    <Modal
      title={t("vehicle:vehicle_location")}
      isOpen={showModal}
      onClose={handleModalClose}
      alignment="center"
    >
      <div className="w-full mx-auto sm:mx-0 max-w-lg lg:w-full lg:max-w-xl px-3">
        <h3 className="text-left text-text-light-100">{location.name}</h3>

        <div className="mt-2 mb-4 border-t-2 border-t-gray-25" />

        <div className="flex flex-col gap-y-2">
          <GoogleMapsWrapper>
            <CurrentHours location={location} detailed eventTag="Locations" />
          </GoogleMapsWrapper>
          {location.address?.address && (
            <div className="flex items-start gap-2">
              <div>
                <MapPinIcon fontSize="1.4rem" className="text-primary-bold" />
              </div>
              <a
                className="text-left text-text-light-100 underline lg:text-lg lg:leading-6"
                href={`http://maps.google.com/?q=${encodeURIComponent(
                  `RightRide ${location.name}`
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                {fullAddress}
              </a>
            </div>
          )}

          {location.phone && (
            <div className="flex items-start gap-2">
              <div>
                <PhoneIcon fontSize="1.4rem" className="text-primary-bold" />
              </div>
              <a
                id={`tel-location-phone-${location.name}`}
                className="text-left text-text-light-100 underline lg:text-lg lg:leading-6"
                href={`tel:+1${location.phone.replace(/\D/g, "")}`}
              >
                {formatPhoneNumber(location.phone)}
              </a>
            </div>
          )}

          {location.email && (
            <div className="flex items-start gap-2">
              <div>
                <EmailIcon fontSize="1.4rem" className="text-primary-bold" />
              </div>
              <a
                id={`tel-location-email-${location.name}`}
                className="text-left text-text-light-100 underline lg:text-lg lg:leading-6"
                href={`mailto:${location.email}`}
              >
                {location.email}
              </a>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VehicleLocationModal;
