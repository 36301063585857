import Image, { ImageProps } from "next/image";
import homenetImageLoader from "@common/helpers/imageLoaders/homenetImageLoader";

type Props = Omit<ImageProps, "loader">;

const HomenetImage = ({ src, ...props }: Props) => {
  return (
    // eslint-disable-next-line react/forbid-elements
    <Image src={src} loader={homenetImageLoader} {...props} />
  );
};

export default HomenetImage;
