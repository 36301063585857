import { ChangeEvent, ReactNode } from "react";
import { useDecimalMask, useNumberMask } from "@common/hooks/useMask";
import { UseFormRegisterReturn } from "react-hook-form";
import { MoneyIcon } from "../icons";
import Input from "./Input";
import { Status } from "./types";
import Form from ".";
import { LabelTextStyle } from "./Label";

type MoneyInputProps = {
  id?: string;
  label?: string;
  value?: number;
  locale: string;
  onChange?(val: number, originalVal: string): void;
  onFocus?(): void;
  isForm?: boolean;
  isOptional?: boolean;
  register?: UseFormRegisterReturn;
  feedback?: ReactNode;
  status?: Status;
  maxLength?: number;
  defaultValue?: number;
  labelTextStyle?: LabelTextStyle;
  suppressHotjar?: boolean;
  useDecimals?: boolean;
  subLabel?: ReactNode;
  className?: string;
  disabled?: boolean;
  readOnly?: boolean;
  offsetIcon?: ReactNode;
  rightIconClickable?: boolean;
};

const MoneyInput = ({
  id = "",
  label = "",
  value,
  locale,
  onChange,
  onFocus,
  register,
  feedback,
  status,
  maxLength,
  defaultValue,
  labelTextStyle = "gray",
  isForm = false,
  suppressHotjar,
  useDecimals = false,
  isOptional = false,
  subLabel,
  className = "",
  disabled = false,
  readOnly = false,
  offsetIcon = undefined,
  rightIconClickable = false,
}: MoneyInputProps) => {
  const { maskPipe: numberMaskPipe, unMaskPipe: numberUnMaskPipe } =
    useNumberMask();
  const { maskPipe: decimalMaskPipe, unMaskPipe: decimalUnMaskPipe } =
    useDecimalMask();

  const unmask = (event: ChangeEvent<HTMLInputElement>) => {
    const { value: v } = event.currentTarget;

    if (onChange) {
      onChange(useDecimals ? decimalUnMaskPipe(v) : numberUnMaskPipe(v), v);
    }
  };

  const getMoneyIcon = () => (
    <MoneyIcon className="text-text-light-100 text-lg font-medium" />
  );

  const getValue = (valueToCheck: number | undefined | null) => {
    if (valueToCheck === undefined || valueToCheck === null) return undefined;

    return useDecimals
      ? decimalMaskPipe(valueToCheck?.toString(), locale)
      : numberMaskPipe(valueToCheck?.toString(), locale);
  };

  return (
    <>
      {isForm && (
        <Form.Input
          id={id}
          label={label}
          defaultValue={getValue(defaultValue)}
          leftIcon={locale !== "fr" ? getMoneyIcon() : offsetIcon}
          rightIcon={locale === "fr" ? getMoneyIcon() : offsetIcon}
          rightIconClickable={rightIconClickable}
          status={status}
          feedback={feedback}
          maxLength={maxLength}
          onFocus={onFocus}
          labelTextStyle={labelTextStyle}
          suppressHotjar={suppressHotjar}
          isOptional={isOptional}
          subLabel={subLabel}
          {...register}
          className={className}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
      {!isForm && (
        <Input
          id={id}
          label={label}
          defaultValue={defaultValue}
          leftIcon={locale !== "fr" ? getMoneyIcon() : offsetIcon}
          rightIcon={locale === "fr" ? getMoneyIcon() : offsetIcon}
          rightIconClickable={rightIconClickable}
          onChange={unmask}
          value={getValue(value)}
          onFocus={onFocus}
          maxLength={maxLength}
          labelTextStyle={labelTextStyle}
          isOptional={isOptional}
          subLabel={subLabel}
          className={className}
          disabled={disabled}
          readOnly={readOnly}
        />
      )}
    </>
  );
};

export default MoneyInput;
