import translate from "@common/helpers/translate";
import { Translation } from "@common/types/Translation";

type AltTextVehicle = {
  exteriorColour?: Translation | string;
  year: number;
  make: string;
  model: string;
  trim?: string | null;
};

function getVehicleAltTextDescription(
  vehicle: AltTextVehicle | null | undefined,
  locale: string
) {
  if (vehicle == null) return "";

  let extColor: string | null = null;

  if (
    vehicle.exteriorColour != null &&
    typeof vehicle.exteriorColour === "string"
  ) {
    extColor = vehicle.exteriorColour;
  } else if (vehicle.exteriorColour != null) {
    extColor = translate(vehicle.exteriorColour, locale);
  }

  return (
    [extColor, vehicle.year, vehicle.make, vehicle.model, vehicle.trim]
      .filter((prop) => {
        return prop != null;
      })
      .join(" ") || ""
  );
}

export default getVehicleAltTextDescription;
