import HomenetImage from "@common/components/HomenetImage";
import { useMemo } from "react";

type Props = {
  image: { img: string; position: number };
  altText: string;
  imageLoading?: "lazy" | "eager" | undefined;
  priority?: boolean;
  onError: () => void;
};

const defaultVLPImageSize = "640x480";

const VehiclePhotoSlide = ({
  image,
  altText,
  imageLoading,
  priority,
  onError,
}: Props) => {
  const MemoizedHomenetImage = useMemo(
    () => (
      <HomenetImage
        loading={imageLoading}
        src={image.img.replace(".com/", `.com/${defaultVLPImageSize}/`)}
        alt={altText}
        width={640}
        height={480}
        className="inline w-full object-cover h-auto rounded-t-lg"
        decoding="async"
        unoptimized
        priority={priority}
        onError={onError}
      />
    ),
    [altText, image.img, imageLoading, onError, priority]
  );

  return (
    <div className="relative flex-[0_0_100%] w-full">
      <div className="relative overflow-hidden h-auto">
        {MemoizedHomenetImage}
      </div>
    </div>
  );
};

export default VehiclePhotoSlide;
