declare global {
  interface Window {
    ggChat: () => void;
    ggToolbar?: {
      apps?: {
        chat?: {
          startEventChat: (eventId?: number) => void;
        };
      };
    };
  }
}

// Open the Gubagoo chat
export const openChat = () => {
  if (window.ggChat) {
    window.ggChat();
  }
};

// Open the Gubagoo chat with a LivePlay Campaign ID
export const openChatWithEvent = (eventId: number) => {
  if (window.ggToolbar?.apps?.chat?.startEventChat) {
    window.ggToolbar.apps.chat.startEventChat(eventId);
  }
};

export default {};
