import {
  VEHICLE_PRICE_MIN_DIFFERENCE_THRESHOLD,
  VEHILCE_PRICE_MAX_DIFFERENCE_PERCENTAGE_THRESHOLD,
} from "@common/constants";

const canShowPreviousPrice = (
  listingPrice: number,
  previousListingPrice: number
) => {
  const priceDifference = previousListingPrice - listingPrice;
  const aboveMinThreshold =
    previousListingPrice - listingPrice >=
    VEHICLE_PRICE_MIN_DIFFERENCE_THRESHOLD;
  // price drop can't be more than 20% of previous listing priceZ
  const belowMaxThreshold =
    priceDifference <=
    previousListingPrice * VEHILCE_PRICE_MAX_DIFFERENCE_PERCENTAGE_THRESHOLD;

  return aboveMinThreshold && belowMaxThreshold;
};

export default canShowPreviousPrice;
