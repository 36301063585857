import { FavouriteOffIcon, FavouriteOnIcon } from "@common/components/icons";
import classNames from "classnames";
import CircularButton, {
  Props as CircularButtonProps,
} from "@common/components/CircularButton";

type Props = {
  selected?: boolean;
  className?: string;
} & CircularButtonProps;

const FavouriteButton = ({ selected = false, className, ...props }: Props) => {
  return (
    <CircularButton
      type="button"
      tabIndex={0}
      className={classNames(
        "shadow-elevation-02 border-transparent p-2 bg-white focus:outline-none focus-visible:shadow-elevation-03 focus-visible:text-secondary-salmon text-text-light-200 hover:text-secondary-salmon",
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      {selected && (
        <FavouriteOnIcon className="w-7 h-7 md:w-6 md:h-6 text-secondary-salmon" />
      )}
      {!selected && <FavouriteOffIcon className="w-7 h-7 md:w-6 md:h-6" />}
    </CircularButton>
  );
};

export default FavouriteButton;
