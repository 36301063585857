import { useTranslation } from "next-i18next";
import Listbox from "@common/components/form/Listbox";
import useGetLocations from "@modules/locations/hooks/useGetLocations";
import Modal from "@components/Modal";
import Location from "@modules/locations/types/Location";
import { useCallback, useEffect, useState } from "react";
import ActionButton from "@modules/cms/enum/ActionButtonEnum";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  defaultLocation?: Location;
  actionType: ActionButton;
};

const AutoVerifyModal = ({
  isOpen,
  onClose,
  defaultLocation = undefined,
  actionType,
}: Props) => {
  const { t } = useTranslation(["common"]);
  const { locations } = useGetLocations();
  const [selectedLocation, setSelectedLocation] = useState<
    Location | undefined
  >(defaultLocation);
  const [showAutoVerifyIframe, setShowAutoVerifyIframe] = useState(false);
  const [autoVerifyClassName, setAutoVerifyClassName] = useState("");
  const [modalTitle, setModalTitle] = useState<string>(t("rightride_location"));

  const loadAutoVerifyIframe = useCallback(() => {
    if (!selectedLocation) return;
    if (document.getElementById("auto-verify-sdk")) {
      document.getElementById("auto-verify-sdk")?.remove();
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.autoverify.destroy();
    }
    setShowAutoVerifyIframe(true);
    if (actionType === ActionButton.OpenCreditCheck) {
      setAutoVerifyClassName(selectedLocation.autoVerifyCreditCheckClassName);
    } else if (actionType === ActionButton.OpenTradeAppraisal) {
      setAutoVerifyClassName(selectedLocation.autoVerifyTradeInClassName);
    } else {
      throw new Error("Invalid action type");
    }
    setModalTitle("");
    const script = document.createElement("script");
    script.src = selectedLocation.autoVerifySdk;
    script.id = "auto-verify-sdk";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
  }, [selectedLocation, actionType]);

  useEffect(() => {
    if (defaultLocation == null) return;
    loadAutoVerifyIframe();
  }, [loadAutoVerifyIframe, defaultLocation]);

  const onLocationSelectChange = (location: Location): void => {
    setSelectedLocation(location);
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={modalTitle}
      alignment="center"
      buttonDisplay="inline"
    >
      {showAutoVerifyIframe && selectedLocation ? (
        <div
          id="auto-verify-iframe-container"
          className={autoVerifyClassName}
        />
      ) : (
        <div>
          <div className="body-2 mb-8">
            {t("common:what_is_your_closest_or_preferred_location")}
          </div>
          <Listbox
            items={
              locations
                ? locations
                    .filter(
                      (location) =>
                        location.autoVerifySdk &&
                        location.autoVerifyCreditCheckClassName &&
                        location.autoVerifyTradeInClassName
                    )
                    .sort((a, b) => a.name.localeCompare(b.name))
                : []
            }
            label={t("common:locations")}
            keyOption={(item) => item.id}
            displayOption={(item) => item?.name || ""}
            displayButton={(item) => item?.name || t("common:select_location")}
            valueOption={(item) => item}
            onChange={onLocationSelectChange}
            selectedItem={selectedLocation}
            name="closestLocation"
            defaultButtonClassName="text-slate-500"
          />
          <Modal.Buttons
            primaryButtonText={t("common:continue")}
            secondaryButtonText={t("common:cancel")}
            onPrimaryClick={() => loadAutoVerifyIframe()}
            onSecondaryClick={onClose}
          />
        </div>
      )}
    </Modal>
  );
};

export default AutoVerifyModal;
