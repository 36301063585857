import { useEffect, useState } from "react";

interface Device {
  isAndroid: boolean;
  isIos: boolean;
  isSsr: boolean;
  isMobile: boolean;
  isDesktop: boolean;
  isAppleWebKit: boolean;
}

const getDevice = (userAgent: string): Device => {
  const isAndroid = (): boolean => Boolean(userAgent.match(/Android/i));
  const isIos = (): boolean =>
    Boolean(userAgent.match(/iPhone|iPad|iPod|Macintosh/i));
  const isOpera = (): boolean => Boolean(userAgent.match(/Opera Mini/i));
  const isWindows = (): boolean => Boolean(userAgent.match(/IEMobile/i));
  const isSsr = (): boolean => Boolean(userAgent.match(/SSR/i));
  const isMobile = (): boolean =>
    Boolean(isAndroid() || isIos() || isOpera() || isWindows());
  const isDesktop = (): boolean => Boolean(!isMobile() && !isSsr());
  const isAppleWebKit = (): boolean => Boolean(userAgent.match(/AppleWebKit/i));

  return {
    isAndroid: isAndroid(),
    isIos: isIos(),
    isMobile: isMobile(),
    isDesktop: isDesktop(),
    isSsr: isSsr(),
    /**
     * use this to check for iOS, iPad OS, and MacOS
     * iPad Mini is detected as iPad but, iPad Air/Pro aren't, they are macintosh for whatever reason
     * all of them use AppleWebKit so this is more appropriate to use
     */
    isAppleWebKit: isAppleWebKit(),
  };
};
export default function useDevice(): Device | null {
  const [device, setDevice] = useState<Device | null>(null);

  useEffect(() => {
    const userAgent =
      typeof navigator === "undefined" ? "SSR" : navigator.userAgent;
    setDevice(getDevice(userAgent));
  }, []);

  return device;
}
