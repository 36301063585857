import Link from "next/link";
import { MouseEvent, useContext, useEffect, useState } from "react";
import VlpSwoopIcon from "@common/components/icons/VlpSwoopIcon";
import { useRouter } from "next/router";
import {
  formatCurrency,
  formatNumber,
  formatPercent,
} from "@common/helpers/numberFormatting";
import { formatVehicleString, gtmPushData } from "@common/helpers/gtm";
import classNames from "classnames";
import { FavouriteOnIcon, InfoCircleIcon } from "@common/components/icons";
import Tooltip from "@common/components/Tooltip";
import { Trans, useTranslation } from "next-i18next";
import translate from "@common/helpers/translate";
import useUser from "@modules/user/hooks/useUser";
import Snackbar from "@common/components/Snackbar";
import { VehicleCardPromoBanner } from "@modules/vehicle/types/VehicleCardPromoBanner";
import HTTPErrorWithBody from "@modules/api/HTTPErrorWithBody";
import useGetLocations from "@modules/locations/hooks/useGetLocations";
import VehicleList from "../types/vehicleList";
import VehicleSwipeLottie from "./VehicleSwipeLottie";
import VlpVehicleCardPromoBanner from "./VlpVehicleCardPromoBanner";
import FavouriteButton from "./FavouriteButton";
import VehiclePhotoCarousel from "./VehiclePhotoCarousel";
import getVehicleSlug from "../helpers/getVehicleSlug";
import {
  UserFavouriteVehicle,
  UserFavouriteVehicleBadRequest,
} from "../types/UserFavouriteVehicle";
import useAddUserFavouriteVehicleMutation from "../hooks/useAddUserFavouriteVehicleMutation";
import useDeleteUserFavouriteVehicleMutation from "../hooks/useDeleteUserFavouriteVehicleMutation";
import SaveToFavouritesModalContext from "../SaveToFavouritesModalContext";
import getVehicleAltTextDescription from "../helpers/getVehicleAltTextDescription";
import {
  FilterKeys,
  VehicleFilters,
  WarrantyFilter,
} from "../types/vehicleFilter";
import canShowPreviousPrice from "../helpers/canShowPreviousPrice";
import VehicleLocationModal from "./vdp/VehicleLocationModal";
import VehicleCardCTA from "./VehicleCardCTA";
import { VehicleCardCTAType } from "../types/VehicleCardCTAType";

type Props = {
  vehicle: VehicleList;
  index: number;
  numberedCards?: boolean;
  showSwipeAnimation?: boolean;
  isImageCarouselActive?: boolean;
  onFavouriteAdded?: (favourite: UserFavouriteVehicle) => void;
  onFavouriteRemoved?: (favourite: UserFavouriteVehicle) => void;
  onFavouriteLimitExceeded?: () => void;
  eventTag?: string;
  isRightPaymentEligible: boolean;
  vehicleFilters?: VehicleFilters;
  tooltipDefaultOpen?: boolean;
  isTourActive?: boolean;
  vehicleCardPromoBanner?: VehicleCardPromoBanner | null;
  vehicleCardCTA?: VehicleCardCTAType | null;
};

const VehicleCard = ({
  vehicle,
  index,
  numberedCards = false,
  showSwipeAnimation = false,
  isImageCarouselActive = true,
  onFavouriteAdded,
  onFavouriteRemoved,
  onFavouriteLimitExceeded,
  eventTag = "Listing",
  isRightPaymentEligible,
  vehicleFilters,
  tooltipDefaultOpen = false,
  isTourActive = false,
  vehicleCardPromoBanner = null,
  vehicleCardCTA = null,
}: Props) => {
  const { t } = useTranslation(["common", "vehicle"]);
  const router = useRouter();
  const locale = router?.locale;
  const { locations } = useGetLocations();
  const vehicleLocation = locations.find(
    (location) =>
      location.name === vehicle.dealerName?.replace("RightRide ", "")
  );
  const [isHovering, setIsHovering] = useState(false);
  const [isToolTipOpen, setIsToolTipOpen] = useState(tooltipDefaultOpen);
  const [showVehicleLocationModal, setShowVehicleLocationModal] =
    useState<boolean>(false);
  const { user, refetch: refetchUser } = useUser();
  const favourite = user?.favouriteVehicles?.find(
    (f) => f.vehicleId === vehicle.id
  );
  const isFavourite = !!favourite;
  const addUserFavouriteVehicleMutation = useAddUserFavouriteVehicleMutation();
  const deleteUserFavouriteVehicleMutation =
    useDeleteUserFavouriteVehicleMutation();
  const {
    showModalState: showSaveToFavouritesModalState,
    limitExceededState: saveToFavouritesLimitExceededState,
  } = useContext(SaveToFavouritesModalContext);
  const [, setShowSaveToFavouritesModal] = showSaveToFavouritesModalState;
  const [, setIsFavouritesLimitExceeded] = saveToFavouritesLimitExceededState;

  const showOnImagelessVehicles =
    vehicleCardPromoBanner?.showOnImagelessVehicles === true;
  const hasImages = vehicle?.images?.length > 0;

  const [isToolTipOnFocus, setIsToolTipOnFocus] = useState(false);
  const isRightPaymentTour = index === 0;

  const warrantyInfo = vehicle.warranty;

  const filters = vehicleFilters?.filters ?? [];

  const warrantyFilter = filters.find(
    (vf): vf is WarrantyFilter => vf.key === FilterKeys.Warranty
  );

  const warrantyAvailable =
    vehicle &&
    warrantyFilter?.state === true &&
    warrantyInfo.drivetrainWarrantyRemainingMonths != null &&
    warrantyInfo.drivetrainWarrantyRemainingMonths > 0 &&
    (warrantyInfo.drivetrainHasUnlimitedMileage ||
      (warrantyInfo.drivetrainRemainingMileage != null &&
        warrantyInfo.drivetrainRemainingMileage > 0));

  const showPreviousListingPrice =
    vehicle.pricing.previousListingPrice !== null &&
    canShowPreviousPrice(
      vehicle.pricing.listingPrice,
      vehicle.pricing.previousListingPrice
    );

  const handleMouseOver = () => {
    if (isTourActive) {
      return;
    }

    setIsHovering(true);
  };

  const handleMouseOut = () => {
    if (isTourActive) {
      return;
    }

    setIsHovering(false);
    setIsToolTipOpen(false);
    setIsToolTipOnFocus(false);
  };

  const handleImageSlide = () => {
    gtmPushData({
      event: eventTag,
      element: "Tile",
      subElement: "Inventory-Image",
      inventory: formatVehicleString(vehicle),
    });
  };

  const handleToolTipFocus = (val: boolean) => {
    gtmPushData({
      event: eventTag,
      element: "Tile",
      subElement: "Est-Payment",
      inventory: formatVehicleString(vehicle),
    });

    setIsToolTipOnFocus(val);
  };

  const handleToolTipClick = (
    e: MouseEvent<HTMLSpanElement, globalThis.MouseEvent>
  ) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }

    setIsToolTipOpen((prev) => !prev);
  };

  const goToSavedVehicles = () => {
    router.push("/profile/saved-vehicles");
  };

  const showFavouriteVehicleAddedSnackbar = () => {
    Snackbar.Pop({
      message: (
        <span className="flex items-center">
          <FavouriteOnIcon className="inline w-6 h-6 text-secondary-salmon pr-1" />
          {t("common:saved_to_favourites")}
        </span>
      ),
      closeButtonText: t("common:view_all"),
      onClose: goToSavedVehicles,
    });
  };

  const addOrRemoveVehicleToFavourites = async (
    selected: boolean
  ): Promise<string | undefined> => {
    let result: UserFavouriteVehicle;
    await refetchUser();

    try {
      if (selected && favourite) {
        if (user?.favouriteVehicles.find((fv) => fv.id === favourite.id)) {
          gtmPushData({
            event: eventTag,
            element: "Tile",
            subElement: "Remove-Favourite",
            inventory: formatVehicleString(vehicle),
          });

          result = await deleteUserFavouriteVehicleMutation.mutateAsync(
            favourite.id
          );

          if (onFavouriteRemoved) {
            onFavouriteRemoved(result);
          }

          return result.id;
        }
      }
      if (
        !favourite &&
        !user?.favouriteVehicles.find((fv) => fv.vehicleId === vehicle.id)
      ) {
        gtmPushData({
          event: eventTag,
          element: "Tile",
          subElement: "Add-Favourite",
          inventory: formatVehicleString(vehicle),
        });
        result = await addUserFavouriteVehicleMutation.mutateAsync(vehicle.id);

        if (onFavouriteAdded) {
          onFavouriteAdded(result);
        } else {
          showFavouriteVehicleAddedSnackbar();
        }

        return result.id;
      }

      return favourite?.id;
    } catch (error) {
      if (error instanceof HTTPErrorWithBody) {
        const res = error.responseJson as
          | UserFavouriteVehicleBadRequest
          | undefined;
        if (res?.code === "limitExceeded") {
          if (onFavouriteLimitExceeded) {
            onFavouriteLimitExceeded();
          } else {
            setIsFavouritesLimitExceeded(true);
            setShowSaveToFavouritesModal(true);
          }
        }
      }

      return undefined;
    }
  };

  const favouriteClick = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();

    await addOrRemoveVehicleToFavourites(isFavourite);
  };

  const handleVehicleCardClick = () => {
    gtmPushData({
      event: eventTag,
      element: "Tile",
      subElement: "Inventory-Link",
      inventory: formatVehicleString(vehicle),
    });
  };

  useEffect(() => {
    if (!isRightPaymentTour) {
      return;
    }

    setIsToolTipOpen(tooltipDefaultOpen);
    setIsToolTipOnFocus(tooltipDefaultOpen);
  }, [isRightPaymentTour, tooltipDefaultOpen]);

  const [cardOnFocus, setCardOnFocus] = useState(false);

  if (typeof vehicle.pricing.interestPaid === "undefined") {
    // throwing this error is a little more informative than the exception we would get if we
    // allowed the code to throw it later, and behavior should be about the same

    throw Error(
      `Vehicle ${vehicle.id}, ${
        vehicle.stockNumber
      } has no interestPaid property, pricing: ${
        vehicle.pricing ? JSON.stringify(vehicle.pricing) : "undefined"
      }`
    );
  }

  return (
    <div
      className={classNames(
        "relative bg-primary-light-100 rounded-lg shadow-elevation-00 aspect-none pb-4",
        {
          "outline-none shadow-elevation-02": cardOnFocus,
          "z-40": isRightPaymentTour && isTourActive,
        }
      )}
      onMouseEnter={handleMouseOver}
      onMouseLeave={handleMouseOut}
    >
      {(isToolTipOpen || isToolTipOnFocus) && (
        <div
          role="presentation"
          className="absolute inset-0 bg-background-dark-50 bg-opacity-16 z-30 cursor-pointer"
          onClick={() => setIsToolTipOpen(false)}
        />
      )}
      <div className="absolute inset-0 z-[4] pointer-events-none visible md:block lg:hidden">
        {showSwipeAnimation && <VehicleSwipeLottie />}
      </div>
      <div className="flex flex-col justify-between min-h-full">
        <Link
          href={`/car/${vehicle.stockNumber.toLowerCase()}/${getVehicleSlug(
            vehicle
          )}`}
          role="link"
          tabIndex={0}
          onFocus={() => setCardOnFocus(true)}
          onKeyUp={handleVehicleCardClick}
          onClick={handleVehicleCardClick}
          onBlur={() => {
            setCardOnFocus(false);
            setIsToolTipOnFocus(false);
            setIsToolTipOpen(false);
          }}
        >
          <div className="relative w-full rounded-t-lg overflow-hidden">
            <VehiclePhotoCarousel
              vehicle={vehicle}
              altText={getVehicleAltTextDescription(vehicle, locale ?? "en")}
              isActive={isImageCarouselActive}
              isHovering={isHovering}
              isAboveTheFold={index < 10}
              onImageSlide={handleImageSlide}
            />
            {numberedCards && (
              <div className="absolute top-3 right-3 w-5 h-5 text-white text-center rounded-full bg-primary-bold">
                {index + 1}
              </div>
            )}
            {vehicleCardPromoBanner != null &&
              showPreviousListingPrice &&
              (showOnImagelessVehicles || hasImages) && (
                <VlpVehicleCardPromoBanner
                  className="absolute z-[3]"
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...vehicleCardPromoBanner}
                />
              )}
            <VlpSwoopIcon className="absolute w-[101%] inset-x-0 bottom-0 z-[1] scale-[1.05] text-white" />
            <FavouriteButton
              className="absolute bottom-1 sm:bottom-3 right-2 z-[3]"
              onClick={favouriteClick}
              selected={isFavourite}
            />
          </div>
          <div className="flex flex-col mt-2 mx-5 min-h-[5.5rem]">
            <p className="text-xs font-normal">{`${t("common:stock")}: ${
              vehicle.stockNumber
            }`}</p>
            <h5 className="z-5">
              {vehicle.year} {vehicle.make} {vehicle.model}
            </h5>
            <p className="caption-2 uppercase text-gray-500">
              {vehicle.trim && <span>{vehicle.trim}</span>}
              {!vehicle.trim && <span>&nbsp;</span>}
            </p>
            <div className="flex flex-grow items-end justify-between">
              <p className="body-3 text-gray-500">
                {vehicle.odometerCount > 0 && (
                  <span>
                    {formatNumber(vehicle.odometerCount, locale || "en")} KM
                  </span>
                )}
              </p>
              <div className="flex items-center">
                <p
                  className={classNames("body-3 text-gray-500", {
                    "body-3 text-gray-500 font-semibold mr-1":
                      showPreviousListingPrice,
                  })}
                >
                  {vehicle.pricing.listingPrice !== 0 && (
                    <span>
                      {formatCurrency(
                        vehicle.pricing.listingPrice,
                        locale || "en",
                        { showCents: "never" }
                      )}
                    </span>
                  )}
                  {vehicle.pricing.listingPrice === 0 && <span>&nbsp;</span>}
                </p>
                <p className="text-sm text-gray-500 leading-6 line-through">
                  {!!vehicle?.pricing?.previousListingPrice &&
                    showPreviousListingPrice && (
                      <span>
                        {formatCurrency(
                          vehicle.pricing.previousListingPrice,
                          locale || "en",
                          { showCents: "never" }
                        )}
                      </span>
                    )}
                </p>
              </div>
            </div>
          </div>
          {warrantyAvailable && (
            <div className="flex flex-col pt-3 mx-5 border-t-2 border-t-gray-50 focus:outline-none">
              <div className="caption-1 text-gray-500">
                <Trans
                  t={t}
                  i18nKey="vehicle:vlp_remaining_warranty"
                  values={{
                    monthsRemaining:
                      warrantyInfo.drivetrainWarrantyRemainingMonths,
                    mileageRemaining: warrantyInfo.drivetrainHasUnlimitedMileage
                      ? ""
                      : `${t(
                          "common:or"
                        )} ${warrantyInfo?.drivetrainRemainingMileage?.toLocaleString()} KMs`,
                  }}
                  components={{
                    monthsRemainingTag: <span />,
                    mileageRemainingTag: <span />,
                  }}
                />
              </div>
            </div>
          )}
          {vehicle.pricing.listingPrice === 0 ? (
            <div className="flex flex-col mt-2 pt-3 mx-5 border-t-2 border-t-gray-50 focus:outline-none">
              <span
                role="presentation"
                className="text-primary-bold font-medium text-base underline hover:cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setShowVehicleLocationModal(true);
                }}
              >
                {t("common:contact_for_pricing")}
              </span>
              <span className="text-xs text-gray-500 font-medium">
                {vehicle.dealerName}
              </span>
            </div>
          ) : (
            <div
              id={isRightPaymentTour ? "right-payment-vehicle-tour" : ""}
              className="flex items-center justify-between mt-2 pt-3 mx-5 border-t-2 border-t-gray-50 focus:outline-none"
            >
              <div className="focus:outline-none">
                <button
                  type="button"
                  tabIndex={0}
                  onFocus={() => {
                    handleToolTipFocus(true);
                  }}
                  onBlur={() => {
                    handleToolTipFocus(false);
                  }}
                  onClick={handleToolTipClick}
                  className="flex flex-col"
                >
                  <span className="justify-start text-primary-bold font-medium text-base underline">
                    <Trans
                      t={t}
                      i18nKey={
                        isRightPaymentEligible
                          ? "common:right_payment"
                          : "common:est_payment"
                      }
                      components={{
                        1: <span />,
                        2: <span />,
                      }}
                    />
                    <Tooltip
                      content={
                        <div className="py-3 text-gray-500 text-center">
                          *
                          {t("common:payment_calculated_based", {
                            month: vehicle.pricing.term,
                            interestRate: formatPercent(
                              vehicle.pricing.interestRate,
                              locale || "en"
                            ),
                            interestPaid: formatCurrency(
                              vehicle.pricing.interestPaid,
                              locale || "en"
                            ),
                          })}
                        </div>
                      }
                      toolTipStyle="light"
                      arrowPlacement="bottom"
                      show={isToolTipOpen || isToolTipOnFocus}
                      displayOn="click"
                      toolTipClassName={classNames("w-56", {
                        "right-payment-tooltip-tour": isRightPaymentTour,
                      })}
                    >
                      <InfoCircleIcon className="relative top-[3px] ml-1 text-primary-bold" />
                    </Tooltip>
                  </span>
                  <p className="justify-start text-xs text-gray-500 font-medium">
                    {`${formatCurrency(
                      vehicle.pricing.cashDown,
                      locale || "en",
                      {
                        showCents: "never",
                      }
                    )} ${t("common:cash_down")}`}
                  </p>
                  {vehicle.pricing.tradeInValue > 0 && (
                    <p className="justify-start text-xs text-gray-500 font-medium">
                      {t("common:trade_in_with_amount", {
                        amount: formatCurrency(
                          vehicle.pricing.tradeInValue,
                          locale || "en",
                          { showCents: "never" }
                        ),
                      })}
                    </p>
                  )}
                </button>
              </div>
              <div className="text-center focus:outline-none">
                <span className="text-primary-deep font-medium text-2xl leading-7 tracking-tight">
                  {formatCurrency(
                    vehicle.pricing.paymentAmount,
                    locale || "en",
                    {
                      showCents: "never",
                    }
                  )}
                  *
                </span>
                <p className="text-xs font-semibold text-gray-500 tracking-widest uppercase">
                  {translate(
                    vehicle.pricing.paymentFrequency.name,
                    locale || "en"
                  )}
                </p>
              </div>
            </div>
          )}
        </Link>
        {vehicleCardCTA && vehicleLocation && (
          <VehicleCardCTA
            button={vehicleCardCTA.button}
            vehicleDetail={{
              ...vehicle,
              images: vehicle.images.map((image) => image.img),
              location: vehicleLocation,
            }}
          />
        )}
      </div>
      {showVehicleLocationModal && vehicleLocation && (
        <VehicleLocationModal
          showModal={showVehicleLocationModal}
          handleModalClose={() => setShowVehicleLocationModal(false)}
          location={vehicleLocation}
        />
      )}
    </div>
  );
};

export default VehicleCard;
