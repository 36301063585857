import { useRouter } from "next/router";
import LocalImage from "@common/components/LocalImage";
import VehicleList from "../types/vehicleList";
import getVehicleAltTextDescription from "../helpers/getVehicleAltTextDescription";
import { VehicleDetail } from "../types/VehicleDetail";

type NewArrivalImageVLPProps = {
  vehicle: VehicleList;
  width: number;
  height: number;
  className: string;
};

type NewArrivalImageVDPProps = {
  vehicle?: VehicleDetail;
  width: number;
  height: number;
  className: string;
};

export const newArrivals = [
  {
    body_styles: ["Sedan", "Coupe", "Hatchback", "Convertible"],
    image: {
      VLP: "/images/new-arrival/VLPCar.png",
      VDP: "/images/new-arrival/VDPCar.png",
    },
  },
  {
    body_styles: ["SUV"],
    image: {
      VLP: "/images/new-arrival/VLPSuv.png",
      VDP: "/images/new-arrival/VDPSuv.png",
    },
  },
  {
    body_styles: ["Van", "Wagon", "Minivan"],
    image: {
      VLP: "/images/new-arrival/VLPVan.png",
      VDP: "/images/new-arrival/VDPVan.png",
    },
  },
  {
    body_styles: ["Truck", "Pickup"],
    image: {
      VLP: "/images/new-arrival/VLPTruck.png",
      VDP: "/images/new-arrival/VDPTruck.png",
    },
  },
];

export const NewArrivalImageVDP = ({
  vehicle,
  width,
  height,
  className,
}: NewArrivalImageVDPProps) => {
  const router = useRouter();
  const locale = router?.locale;
  const newArrivalImage = newArrivals.find((newArrival) =>
    newArrival.body_styles.includes(
      (locale === "en"
        ? vehicle?.bodyStyle?.english
        : vehicle?.bodyStyle?.french) ?? ""
    )
  );

  return (
    <LocalImage
      src={newArrivalImage?.image.VDP ?? newArrivals[0].image.VDP}
      alt={getVehicleAltTextDescription(vehicle, locale ?? "en")}
      width={width}
      height={height}
      className={className}
    />
  );
};

export const NewArrivalImageVLP = ({
  vehicle,
  width,
  height,
  className,
}: NewArrivalImageVLPProps) => {
  const router = useRouter();
  const locale = router?.locale;
  const newArrivalImage = newArrivals.find((newArrival) =>
    newArrival.body_styles.includes(vehicle.bodyStyle)
  );

  return (
    <LocalImage
      src={newArrivalImage?.image.VLP ?? newArrivals[0].image.VLP}
      alt={getVehicleAltTextDescription(vehicle, locale ?? "en")}
      width={width}
      height={height}
      className={className}
    />
  );
};

export default {};
