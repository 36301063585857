import { gtmPushData } from "@common/helpers/gtm";
import CreditAppModalContext from "@modules/credit-app/CreditAppModalContext";
import { introductionMode } from "@modules/credit-app/types/CreditAppModalMode";
import AutoVerifyModal from "@modules/auto-verify-modal/AutoVerifyModal";
import { useContext, useEffect, useState } from "react";
import { Event as HotjarEvent, hotjar } from "@modules/core/hotjar/types";
import Modal from "@common/components/Modal";
import classNames from "classnames";
import Snackbar from "@common/components/Snackbar";
import {
  openChat,
  openChatWithEvent,
} from "@modules/core/gubagoo/gubagooService";
import { useTranslation } from "next-i18next";
import {
  BaseVehicleDetail,
  VehicleDetail,
} from "@modules/vehicle/types/VehicleDetail";
import ActionButton from "../enum/ActionButtonEnum";
import { GravityFormSide } from "../types/GravityFormLayout";
import GravityForm from "./GravityForm";
import GetBackgroundStyle from "../helpers/getBackgroundStyle";

type FormProps = GravityFormSide;

export type ButtonModalState = {
  show: boolean;
  actionType: ActionButton;
  gubagooEventId?: number;
  formProps?: FormProps;
};

type Props = {
  buttonModalState: ButtonModalState;
  vehicle?: VehicleDetail | BaseVehicleDetail;
};

const CmsButtonModalContainer = ({ buttonModalState, vehicle }: Props) => {
  const { t } = useTranslation(["common"]);
  const [buttonModalComponent, setButtonModalComponent] =
    useState<JSX.Element | null>(null);

  const { setCreditAppShowModal, creditAppModeState } = useContext(
    CreditAppModalContext
  );
  const [, setCreditAppModeState] = creditAppModeState;

  useEffect(() => {
    switch (buttonModalState.actionType) {
      case ActionButton.OpenCreditCheck:
        setButtonModalComponent(
          <AutoVerifyModal
            isOpen={buttonModalState.show}
            onClose={() => setButtonModalComponent(null)}
            actionType={buttonModalState.actionType}
          />
        );
        break;
      case ActionButton.OpenTradeAppraisal:
        gtmPushData({
          event: "Trade-In",
          element: "CTA",
          descriptor: "Get-Your-Offer",
        });
        setButtonModalComponent(
          <AutoVerifyModal
            isOpen={buttonModalState.show}
            onClose={() => setButtonModalComponent(null)}
            actionType={buttonModalState.actionType}
          />
        );
        break;
      case ActionButton.OpenCreditApp:
        gtmPushData({
          event: "Prequalification",
          element: "CTA",
          descriptor: "Start-Pre-Approval",
        });
        hotjar.event(HotjarEvent.CreditApplication);
        setCreditAppModeState(introductionMode);
        setCreditAppShowModal(buttonModalState.show);
        break;
      case ActionButton.OpenGubagooChat:
        if (buttonModalState.gubagooEventId) {
          openChatWithEvent(buttonModalState.gubagooEventId);
        } else {
          openChat();
        }
        break;
      case ActionButton.GravityForm: {
        if (!buttonModalState.formProps?.gravityForm.form) {
          break;
        }

        const {
          gravityForm: { form, id: gravityFormsId, campaignTag, background },
          heading,
          subheading,
        } = buttonModalState.formProps;

        setButtonModalComponent(
          <Modal
            alignment="center"
            isOpen={buttonModalState.show}
            onClose={() => setButtonModalComponent(null)}
          >
            <div
              className="w-full p-8 rounded-lg"
              style={GetBackgroundStyle(background)}
            >
              {heading?.isActive && (
                <div className="p-4 text-center">
                  <h3
                    className={classNames("text-primary-deep leading-snug", {
                      "mb-4": subheading.isActive,
                    })}
                  >
                    {heading.label ?? form.title}
                  </h3>
                  <p className="text-base text-gray-500">
                    {subheading?.isActive ? subheading.label : form.description}
                  </p>
                </div>
              )}
              <div className="w-full text-center space-y-3">
                <GravityForm
                  form={form}
                  formId={gravityFormsId}
                  campaignTag={campaignTag}
                  submitButtonText={form.submitButton?.text}
                  isPopup
                  onSubmit={() => {
                    Snackbar.Pop({
                      message:
                        form.confirmations?.[0]?.message ||
                        t("common:thanks_for_applying"),
                    });
                    setButtonModalComponent(null);
                  }}
                  onError={() => {
                    Snackbar.Pop({
                      message: t("common:something_went_wrong"),
                    });
                  }}
                  vehicle={vehicle}
                />
              </div>
            </div>
          </Modal>
        );
        break;
      }
      default:
        break;
    }
    // Disable dep warning as vehicle causes infinite render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCreditAppShowModal, buttonModalState, setCreditAppModeState, t]);

  return (
    <div>
      {buttonModalComponent && buttonModalState.show && (
        <div> {buttonModalComponent} </div>
      )}
    </div>
  );
};

export default CmsButtonModalContainer;
