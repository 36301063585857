import { ChevronLeftIcon } from "@common/components/icons";
import classNames from "classnames";
import { MouseEvent, useEffect, useState } from "react";

type Props = {
  goToPrevious?: (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => void;
  goToNext?: (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void;
  isHovering: boolean;
  size?: "small" | "large";
};

const VehicleCarouselButtons = ({
  goToPrevious,
  goToNext,
  isHovering = false,
  size = "small",
}: Props) => {
  const [leftButtonOnFocus, setLeftButtonOnFocus] = useState(false);
  const [rightButtonOnFocus, setRightButtonOnFocus] = useState(false);

  useEffect(() => {
    if (isHovering === false) {
      setLeftButtonOnFocus(false);
      setRightButtonOnFocus(false);
    }
  }, [isHovering]);

  return (
    <div className="animate-delay-show">
      <button
        tabIndex={0}
        className={classNames(
          "opacity-0 focus:outline-none focus-visible:shadow-elevation-01 absolute top-0 bottom-0 flex items-center justify-center p-0 border-0 hover:outline-none hover:no-underline focus:no-underline left-0",
          {
            "animate-delay-show opacity-100": isHovering || leftButtonOnFocus,
            "w-10": size === "small",
            "w-[7.5rem]": size === "large",
          }
        )}
        onFocus={() => setLeftButtonOnFocus(true)}
        onBlur={() => setLeftButtonOnFocus(false)}
        style={{
          background:
            "linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(217, 217, 217, 0) 99.99%, rgba(217, 217, 217, 0) 100%)",
        }}
        type="button"
        onClick={goToPrevious}
        aria-label="Previous photo"
      >
        <ChevronLeftIcon className="h-12 w-12 text-primary-light-100" />
      </button>
      <button
        className={classNames(
          "opacity-0 focus:outline-none focus-visible:shadow-elevation-01 absolute top-0 bottom-0 flex items-center justify-center p-0 border-0 hover:outline-none hover:no-underline focus:no-underline right-0",
          {
            "animate-delay-show opacity-100": isHovering || rightButtonOnFocus,
            "w-10": size === "small",
            "w-[7.5rem]": size === "large",
          }
        )}
        style={{
          background:
            "linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(217, 217, 217, 0) 99.99%, rgba(217, 217, 217, 0) 100%)",
          transform: "matrix(-1, 0, 0, 1, 0, 0)",
        }}
        type="button"
        onClick={goToNext}
        onFocus={() => setRightButtonOnFocus(true)}
        onBlur={() => setRightButtonOnFocus(false)}
        aria-label="Next photo"
      >
        <ChevronLeftIcon className="h-12 w-12 text-primary-light-100" />
      </button>
    </div>
  );
};

export default VehicleCarouselButtons;
