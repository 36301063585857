/* eslint-disable import/extensions */
import { LottiePlayer } from "lottie-web";
import { useEffect, useRef, useState } from "react";
import data from "./vehicle-swipe.json";

const VehicleSwipeLottie = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [lottie, setLottie] = useState<LottiePlayer | null>(null);

  useEffect(() => {
    import("lottie-web").then((l) => setLottie(l.default));
  }, []);

  useEffect(() => {
    if (lottie && ref.current) {
      const animation = lottie.loadAnimation({
        container: ref.current,
        renderer: "svg",
        animationData: data,
        loop: 2,
        autoplay: true,
      });

      animation.addEventListener("complete", () => {
        animation.destroy();
      });
      return () => animation.destroy();
    }
    return undefined;
  }, [lottie]);

  return <div ref={ref} />;
};

export default VehicleSwipeLottie;
