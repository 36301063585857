import { useContext, useState } from "react";
import { ArrowRightIcon } from "@common/components/icons";
import CmsButtonModalContainer from "@modules/cms/components/ButtonModalContainer";
import CmsButton from "@modules/cms/components/CmsButton";
import ActionButton from "@modules/cms/enum/ActionButtonEnum";
import { CmsButtonType } from "@modules/cms/types/CmsButton";
import CreditAppModalContext from "@modules/credit-app/CreditAppModalContext";
import { BaseVehicleDetail } from "../types/VehicleDetail";

type Props = {
  button: CmsButtonType;
  vehicleDetail: BaseVehicleDetail;
};

const VehicleCardCTA = ({ button, vehicleDetail }: Props) => {
  const { vehicleDetail: vehicleState } = useContext(CreditAppModalContext);
  const [, setVehicleDetail] = vehicleState ?? [];
  const [buttonModalState, setButtonModalState] = useState<{
    show: boolean;
    actionType: ActionButton;
  }>({ show: false, actionType: ActionButton.UnAssigned });
  return (
    <>
      <div id="cms-vehicle-card-cta" className="max-w-full mx-4 mt-4">
        <CmsButton
          className="w-full"
          button={button}
          setButtonModalState={setButtonModalState}
          onClick={() => {
            if (setVehicleDetail && vehicleDetail) {
              setVehicleDetail(vehicleDetail);
            }
          }}
          spacing="tight-hug"
          rightIcon={<ArrowRightIcon className="h-3.5 w-3.5" />}
        />
      </div>
      {buttonModalState.show && (
        <CmsButtonModalContainer
          buttonModalState={buttonModalState}
          vehicle={vehicleDetail}
        />
      )}
    </>
  );
};

export default VehicleCardCTA;
